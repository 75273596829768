import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ButtonIcon from "../icon/buttonIcon"

const CsListing = () => {
    return(
        <section className="cs-listing">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="text-center">
                        <h1 className="sub-title">Unlocking possibilities. One project at a time</h1>
                        <p className="sub-desc">Take a glimpse through Case Studies of our recent projects and understand how we’ve helped these organisations to delight their customers</p>                        
                    </Col>
                    <Col lg={12} className="mt-md-5 pt-md-5 mt-3 pt-3">
                        <Link to="/case-studies/fifa-arab-cup-2021/" className="cs-featured-landing">
                            <Row className="align-items-center mx-0">
                                <Col md={6} className="px-0">
                                    <StaticImage src="../../images/case-studies/fifa-arab-cup-2021/fifa-ac-case-study-listing-thumbnail.png" alt="Fifa Arab Cup 2021 UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={1200} height={1200}/>
                                </Col>
                                <Col md={6}>
                                    <div className="cs-main-content">
                                        <span className="section-tag">Sports<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                        <div className="cs-landing-c-header mt-3">
                                            <h3 className="sub-title">How we designed Fan ID Platform for Fifa Arab Cup 2021</h3>
                                        </div>
                                        <div className="btn wowaction-btn">
                                            <ButtonIcon />
                                            <span>Read Case Study</span>
                                        </div>                                        
                                    </div>
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                    <Col lg={12} className="mt-5">
                        <Row>
                            <Col lg={4}>
                                <Link to="/case-studies/ashghals-travel-app/" className="home-cs-link first">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/ashghals-travel-app/cs-ashghals-travel-app.png"
                                    alt="Ashghals Travel App UX/UI Case studies | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Government<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                    <div className="cs-header">
                                    <h3>
                                        How we helped the Qatari Government design a Travel Information System
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                        
                            <Col lg={4}>
                                <Link to="/case-studies/trax-mobile-app/" className="home-cs-link middle">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/trax-mobile-app/cs-trax-bike-shop-app.png"
                                    alt="Trax Mobile App UX/UI Case Study | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    className="img-fluid"
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">eCommerce<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                    <div className="cs-header">
                                    <h3>
                                        Reimagining an eCommerce experience for e-bike enthusiasts with TRAX
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link to="/case-studies/bfc-payments/" className="home-cs-link last">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/bfc-payments/bfc-home-page-thumbnail.png"
                                    alt="Golazo Case Study"
                                    placeholder="blurred"
                                    width={1014}
                                    height={708}
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Fintech<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                    <div className="cs-header">
                                    <h3>
                                        How our UI/UX team made BFC Payment app the trusted choice in Money transfer, Currency exchange and Bill payments.
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                   
                        </Row>
                    </Col>
                    <Col lg={12} className="mt-5">
                        <Link to="/case-studies/electric-car-commute-app/" className="cs-featured-landing">
                            <Row className="align-items-center mx-0">
                                <Col md={6} className="px-0">
                                    <StaticImage src="../../images/case-studies/electric-car-commute-app/cs-rivian-listing-thumbnail.jpg" alt="Electric Car Commute App UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={1200} height={1200}/>
                                </Col>
                                <Col md={6}>
                                    <div className="cs-main-content">
                                        <span className="section-tag">Automotive<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                        <div className="cs-landing-c-header mt-3">
                                            <h3 className="sub-title">Transforming Electric Vehicle Commuting with our Intuitive UX/UI Design</h3>
                                        </div>
                                        <div className="btn wowaction-btn">
                                            <ButtonIcon />
                                            <span>Read Case Study</span>
                                        </div>                                        
                                    </div>
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                    <Col lg={12} className="mt-5">
                        <Row>
                            <Col lg={4}>
                                <Link to="/case-studies/tbx-trading-app/" className="home-cs-link first">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/tbx-trading-app/cs-tbx-trading-app.png"
                                    alt="TBX Trading App UX/UI Case studies | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Financial Services<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                    <div className="cs-header">
                                    <h3>
                                    How we helped TBX build a marketplace to trade perishable commodities.
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                        
                            <Col lg={4}>
                                <Link to="/case-studies/social-delivery-system/" className="home-cs-link middle">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/social-delivery-system/social-delivery-system.jpg"
                                    alt="Social Delivery System UX/UI Case Study | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    className="img-fluid"
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Logistics<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                    <div className="cs-header">
                                    <h3>
                                        Unleashing the Power of Community-Driven Package Delivery with Raaven
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link to="/case-studies/golazo/" className="home-cs-link last">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/golazo/cs-golazo.jpeg"
                                    alt="Golazo UX/UI Case Study | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    className="img-fluid"
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Digital Products<span className="seperator"><span>.</span></span>UX/UI Design</span>
                                    <div className="cs-header">
                                    <h3>
                                        How our UI/UX team helped Golazo to design and develop a successful goal tracking app
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                   
                        </Row>
                    </Col>
                    <Col lg={12} className="mt-5">
                        <Link to="/case-studies/gwc-logistics/" className="cs-featured-landing">
                            <Row className="align-items-center mx-0">
                                <Col md={6} className="px-0">
                                    <StaticImage src="../../images/case-studies/gwc-logistics/cs-landing-page-featured-gwc-logistics.png" alt="KEBS Explainer Video Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={1200} height={1200}/>
                                </Col>
                                <Col md={6}>
                                    <div className="cs-main-content">
                                        <span className="section-tag">Logistics<span className="seperator"><span>.</span></span>Explainer Video</span>
                                        <div className="cs-landing-c-header mt-3">
                                            <h3 className="sub-title">Learn how we helped Qatar’s leading logistics provider market its grand solutions with a creative explainer video</h3>
                                        </div>
                                        <div className="btn wowaction-btn">
                                            <ButtonIcon />
                                            <span>Read Case Study</span>
                                        </div>                                        
                                    </div>
                                </Col>
                            </Row>
                        </Link>                        
                    </Col>
                    <Col lg={12} className="mt-5">
                        <Row>
                            <Col lg={4}>
                                <Link to="/case-studies/kebs/" className="home-cs-link first">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/kebs/cs-kebs.jpg"
                                    alt="Kebs Explainer Video Case study| WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Technology<span className="seperator"><span>.</span></span>Explainer Video</span>
                                    <div className="cs-header">
                                    <h3>
                                        How we helped Kaar Tech launch their dream product through an engaging video!
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                             
                            <Col lg={4}>
                                <Link to="/case-studies/cult-transform/" className="home-cs-link middle">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/cult-transform/cs-cult-transform.jpg"
                                    alt="Cult Transform Explainer Video Case Study | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    className="img-fluid"
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Fitness & Wellness<span className="seperator"><span>.</span></span>Explainer Video</span>
                                    <div className="cs-header">
                                    <h3>
                                        How we helped Cult Fitness to guide their users on rewarding fitness journeys through their habit-coaching program
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link to="/case-studies/icici-bank/" className="home-cs-link last">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/icici-bank/cs-icici-bank.jpg"
                                    alt="ICICI Bank Explainer Video Case Study | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Banking<span className="seperator"><span>.</span></span>Explainer Video</span>
                                    <div className="cs-header">
                                    <h3>
                                        How our explainer video helped ICICI bank to establish their latest recruitment chatbot
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                            
                        </Row>
                    </Col>
                    <Col lg={12} className="mt-5">
                        <Link to="/case-studies/hdfc/" className="cs-featured-landing">
                            <Row className="align-items-center mx-0">
                                <Col md={6} className="px-0">
                                    <StaticImage src="../../images/case-studies/hdfc/cs-landing-page-featured-HDFC.jpeg" alt="HDFC Bank Explainer Video Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={1200} height={1200}/>
                                </Col>
                                <Col md={6}>
                                    <div className="cs-main-content">
                                        <span className="section-tag">Banking<span className="seperator"><span>.</span></span>Explainer Video</span>
                                        <div className="cs-landing-c-header mt-3">
                                            <h3 className="sub-title">How our digital storytelling skills helped India's largest private sector bank to promote its flagship mobile app</h3>
                                        </div>
                                        <div className="btn wowaction-btn">
                                            <ButtonIcon />
                                            <span>Read Case Study</span>
                                        </div>                                        
                                    </div>
                                </Col>
                            </Row>
                        </Link>                        
                    </Col>                                                            
                    <Col lg={12} className="mt-5">
                        <Row>
                            <Col lg={4}>
                                <Link to="/case-studies/nomidman/" className="home-cs-link first">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/nomidman/cs-nomidman.jpg"
                                    alt="Nomidman Explainer Video UX/UI Case study| WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Blockchain<span class="seperator"><span>.</span></span>UX/UI Design<span class="seperator"><span>.</span></span>Explainer Video</span>
                                    <div className="cs-header">
                                    <h3>
                                        Nobody had heard of the world's first blockchain powered trading platform until we came up with this!
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                             
                            <Col lg={4}>
                                <Link to="/case-studies/prim-technologies/" className="home-cs-link middle">
                                <div className="hcs-img">
                                    <StaticImage
                                    src="../../images/case-studies/prim-technologies/cs-prim-technologies.jpg"
                                    alt="Prim Technologies Explainer Video Case Study | WowMakers"
                                    placeholder="blurred"
                                    width={507}
                                    height={354}
                                    className="img-fluid"
                                    />
                                </div>
                                <div className="hcs-content">
                                    <span className="section-tag">Technology<span className="seperator"><span>.</span></span>Explainer Video</span>
                                    <div className="cs-header">
                                    <h3>
                                        How WowMakers created a compelling video for a virtual mentoring platform
                                    </h3>
                                    </div>
                                    <div className="btn wowaction-btn">
                                    <ButtonIcon />
                                    <span>Read Case Study</span>
                                    </div>
                                </div>
                                </Link>
                            </Col>                            
                        </Row>                                               
                    </Col>                                       
                </Row>
            </Container>
        </section>
    )
}

export default CsListing