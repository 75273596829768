import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import "../../sass/global/testimonials.scss"

const CsTestimonials1 = () => {
  return (
    <section className="testimonials testimonials-inner cs-testimonials-1">
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <Row>
              <Col lg={4} md={6} className="mb-md-0 mb-3">
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Nishevita.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text cst-text-1">
                        “WowMakers implemented feedback right away, responded promptly to questions, and dedicated themselves to delivering great results.”
                    </p>
                    <p className="c-name">
                      <span>Nishevitha Ramamoorthy</span>
                    </p>
                    <p className="c-designtion">
                        Marketing Analyst, Zoho
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} className="mb-md-0 mb-3">
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Joshua.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text cst-text-1">
                        “We have experience working with overseas companies and sometimes it can be difficult, but not with WowMakers.”
                    </p>
                    <p className="c-name">
                      <span>Joshua Theberg</span>
                    </p>
                    <p className="c-designtion">
                        President, Property Force
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/soh-tanaka.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text cst-text-1">
                        “Thanks to the close collaboration and instant feedback from WowMakers, we have completed our project in no time.”
                    </p>
                    <p className="c-name">
                      <span>Daniel Juric</span>
                    </p>
                    <p className="c-designtion">
                        Head of Operations & Key Account Mgmt, SkilldIt
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CsTestimonials1
