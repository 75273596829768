import React from "react"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import CsListing from "../../components/case-studies/csListing"
import CsTestimonials1 from "../../components/case-studies/csTestimonials1"
import CsClientsVerticals from "../../components/case-studies/csClientsVerticals"
import CsTestimonials2 from "../../components/case-studies/csTestimonials2"
import CompanyLogo from "../../components/global/companyLogo"
import ProjectInMind from "../../components/global/projectInMind"
import HomeForm from "../../components/home/homeForm"
import DirectContact from "../../components/global/directContact"
import SeoHead from "../../components/global/seoHead"

import csFeaturedImage from "../../../static/home/wowmakers.jpeg"
import "../../sass/pages/case-studies/cs-main.scss"


export const Head = () => (
  <> 
    <SeoHead
      ogImage={csFeaturedImage}
      title="WowMakers Case Studies"
      description="See how we help our partners and clients to achieve their business goals with our marketing expertise!"
    />
  </> 
)

const CaseStudies = () => {
    return (
      <Layout>
        <Seo
          bclass="case-studies"
          bid="case-studies"
        ></Seo>
        <ScrollIndicationHeightZero />
        <CsListing />
        <CsTestimonials1 />
        <CsClientsVerticals />
        <CsTestimonials2 />
        <CompanyLogo />
        <ProjectInMind title="Are you looking for a partner to work on your project?" />
        <HomeForm page="case-studies"/>
        <DirectContact />
      </Layout>
    )
}
export default CaseStudies
