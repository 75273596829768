import { Link } from "gatsby"
import React from "react"

import { Container, Row, Col } from "react-bootstrap"

const CsClientsVerticals = () => {
    return(
        <section class="clients-certicals">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="text-center">
                        <h1 class="sub-title">Clients & Verticals</h1>
                        <p class="sub-desc">In the last 10 years, we have successfully delivered 328 projects for 147 clients across 12 verticals from 6 continents, by our 38 member team working in 4 countries.</p>                        
                    </Col>
                    <Col md={12} className="pt-md-5 mt-md-5">
                        <Row>
                            <Col md={4}>
                                <ul className="wm-verticals">
                                    <li>
                                        <button className="tab-btn" id="fortune" data-id="#fortune-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Fortune 500</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="bank-fintech" data-id="#bank-fintech-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Banking & Fintech</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="government" data-id="#government-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Government</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="digital-products" data-id="#digital-products-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Digital Products</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn active" id="it-consultancy" data-id="#it-consultancy-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">IT/Consultancy</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="education" data-id="#education-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Education</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="health-wellness" data-id="#health-wellness-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Health & Wellness</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="professional-service" data-id="#professional-service-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Professional Service</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="automotive" data-id="#automotive-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Automotive</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="e-commerce" data-id="#e-commerce-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">E-commerce</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="tab-btn" id="others" data-id="#others-client">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.04 54.7"><polygon fill="#FCF112" points="85.04 27.35 37.05 0 37.05 22.25 0 22.25 0 32.45 37.05 32.45 37.05 54.7 85.04 27.35"></polygon></svg>
                                            <span className="ml-lg-3 ml-1">Others</span>
                                        </button>
                                    </li>                                                                                                                                                                                                                                                                                                                                                                         
                                </ul>
                            </Col>
                            <Col md={8}>
                                <div className="wmtab-content" id="fortune-client">
                                    <h4 class="d-md-none mt-5">Fortune 500</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Microsoft</p>
                                                <p>Mercedez</p>
                                                <p>Citibank</p>
                                                <p>Vodafone</p>
                                                <p>Tencent</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Sanofi</p>
                                                <p>AON</p>
                                                <p>Cognizant</p>
                                                <p>Reliance</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="bank-fintech-client">
                                    <h4 class="d-md-none mt-5">Banking & Fintech</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                            <p>AON</p>
                                            <p class="link-active">
                                                <Link to="/case-studies//hdfc" title="HDFC Case study, WowMakers">HDFC →</Link>
                                            </p>
                                            <p>Citibank</p>
                                            <p>Federal Bank</p>
                                            <p>South Indian Bank</p>
                                            <p>Axis Bank</p>
                                            <p>Utkarsh Small Finance Bank</p>
                                            <p>Paytm</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>PayUmoney</p>
                                                <p>Zerodha</p>
                                                <p>GetWalnut</p>
                                                <p>Geojit</p>
                                                <p>DBL</p>
                                                <p>Tickermachine</p>
                                                <p>Chillr</p>
                                                <p>Trupay</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="government-client">
                                    <h4 class="d-md-none mt-5">Government</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Government of Japan</p>
                                                <p>MME, Govt. of Qatar</p>
                                                <p>Government of India</p>
                                                <p>Government of Kerala</p>
                                                <p>Kochi Metro Rail Limited</p>
                                                <p>Kerala Forest Department</p>
                                                <p>Startup Village</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Govt. of Himachal Pradesh</p>
                                                <p>CDFI</p>
                                                <p>Kerala Startup Mission</p>
                                                <p>IIM Ahmedabad</p>
                                                <p>Technopark Trivandrum</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="digital-products-client">
                                    <h4 class="d-md-none mt-5">Digital Products</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Hike</p>
                                                <p>WeChat</p>
                                                <p>Acebot</p>
                                                <p class="link-active">
                                                    <Link to="/case-studies/nomidman/" title="WowMakers Case study Nomidman">Nomidman →</Link>
                                                </p>
                                                <p>Apartment Adda</p>
                                                <p>Mad Over Poker</p>
                                                <p>Riddle book</p>
                                                <p>OnBlick </p>
                                                <p>SkilldIT</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Ourbus</p>
                                                <p>DriveU</p>
                                                <p>Corpool</p>
                                                <p>Coinome</p>
                                                <p>Rymindr</p>
                                                <p>Wellworth</p>
                                                <p>Sentinel</p>
                                                <p>ZimZim</p>
                                                <p>My Parichay</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content active" id="it-consultancy-client">
                                    <h4 class="d-md-none mt-5">IT/Consultancy</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Microsoft</p>
                                                <p>Cognizant</p>
                                                <p>Infosys</p>
                                                <p>Zoho</p>
                                                <p>Ernst &amp; Young</p>
                                                <p>L&amp;T Infotech</p>
                                                <p>Quick Heal</p>
                                                <p>Hiver</p>
                                                <p>Qmedi Healthcare</p>
                                                <p>Transight</p>
                                                <p>Cognizant</p>
                                                <p>Litmus7</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Ducont</p>
                                                <p>Geckolyst</p>
                                                <p>Hexaware</p>
                                                <p>Hexagon</p>
                                                <p>Pluto7</p>
                                                <p>Prophesee</p>
                                                <p>Tuple</p>
                                                <p>Lateetud</p>
                                                <p>Precision Consulting</p>
                                                <p>July Rapid</p>
                                                <p>Treeni </p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="education-client">
                                    <h4 class="d-md-none mt-5">Education</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>ICT Academy</p>
                                                <p>EkStep</p>
                                                <p>Startup Village</p>
                                                <p>Conduira Education</p>
                                                <p>Tutorcomp</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Entri.me</p>
                                                <p>Explorars</p>
                                                <p>SV.CO</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="health-wellness-client">
                                    <h4 class="d-md-none mt-5">Health & Wellness</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Sanofi</p>
                                                <p>Strides Pharma</p>
                                                <p>Arjuna Naturals</p>
                                                <p>Carestack</p>
                                                <p>Hubo Fitness</p>
                                                <p>MapMyDoctor</p>
                                                <p>Dr. Appointment</p>
                                                <p>Promax - Cardiomeet</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>4Tranz4</p>
                                                <p>MyMedicine</p>
                                                <p>Oxygen Healthcare</p>
                                                <p>Qyura</p>
                                                <p>Neyyar</p>
                                                <p>Hubo Fitness</p>
                                                <p>ZF Life Care</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="professional-service-client">
                                    <h4 class="d-md-none mt-5">Professional Service</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Havas Worldwide</p>
                                                <p>Ogilvy</p>
                                                <p>Positive Integers</p>
                                                <p>Constreite Qatar</p>
                                                <p>CoMakeIT</p>
                                                <p>Impetus</p>
                                                <p>SayOne Technologies</p>
                                                <p>Cabot Solutions</p>
                                                <p>Advenser</p>
                                                <p>BrandMonsters</p>
                                                <p>MobME</p>
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Kreata Global</p>
                                                <p>Innovature Labs</p>
                                                <p>American Manuscript</p>
                                                <p>Creativeland Asia</p>
                                                <p>Fisheye Creative</p>
                                                <p>Perdix Global</p>
                                                <p>Siiana</p>
                                                <p>Smartvision</p>
                                                <p>NextWatt</p>
                                                <p>Tattwa</p>
                                                <p>Kronos</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="automotive-client">
                                    <h4 class="d-md-none mt-5">Automotive</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>Mercedez Benz</p>
                                                <p>Bharat Benz</p>
                                                <p>Tyre Dekho</p>                                                
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Kuttukaran</p>
                                                <p>Eram Motors</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="e-commerce-client">
                                    <h4 class="d-md-none mt-5">E-commerce</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>ST-YL</p>
                                                <p>MayaBae</p>
                                                <p>Efundbook </p>
                                                <p>Pharmeasy</p>
                                                <p>Monest</p>                                                
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Silverline</p>
                                                <p>Taglr</p>
                                                <p>Tompang</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                                <div className="wmtab-content" id="others-client">
                                    <h4 class="d-md-none mt-5">Others</h4>
                                    <Row>
                                        <Col md={5} className="offset-md-1">
                                            <div className="client-list">
                                                <p>CUMI</p>
                                                <p>NeST Hi-Tec Park</p>
                                                <p>Dreamland Adventures</p>
                                                <p>Jackfruit 365</p>
                                                <p>VegFru</p>
                                                <p>Cosmos</p>
                                                <p>Travel Triangle</p>
                                                <p>Bharat Innovation fund</p>
                                                <p>Locus</p>
                                                <p>Property Force</p>
                                                <p>Semilon</p>
                                                <p>Lulu Sweets</p>                                                
                                            </div>
                                        </Col>
                                        <Col md={5} className="offset-lg-1">
                                            <div className="client-list">
                                                <p>Moonshot Entertainment</p>
                                                <p>Agorsa Gourmet</p>
                                                <p>Praud Food</p>
                                                <p>Hamsini Entertainment</p>
                                                <p></p>
                                                <p>Golden Valley</p>
                                                <p>Mughal Mahal</p>
                                                <p>Good Day Cafe</p>
                                                <p>Shipnext</p>
                                                <p>FDFS</p>
                                                <p>Tree of Life</p>
                                                <p>Burgeria</p>
                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>                                                                                                                                                                                                                                                                                                                                  
                            </Col>
                        </Row>
                    </Col>                                       
                </Row>
            </Container>
        </section>
    )
}

export default CsClientsVerticals