import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import "../../sass/global/testimonials.scss"

const CsTestimonials2 = () => {
  return (
    <section className="testimonials testimonials-inner cs-testimonials-2">
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <Row>
              <Col lg={4} md={6} className="mb-md-0 mb-3">
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Nikhil.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                        “WowMakers added a personality to our new brand, which is easy for all to understand and deliver the key message as expected. The constant communication and roundups kept the overall process in flow and well executed. ”
                    </p>
                    <p className="c-name">
                      <span>Nikhil Khatri</span>
                    </p>
                    <p className="c-designtion">
                        Manager, Corporate Communications, QuickHeal
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} className="mb-md-0 mb-3">
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Siddharth.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                        “WowMakers stood to their 'Wow' factor and delivered a great marketing asset for us. They were very professional and flexible in their approach.”
                    </p>
                    <p className="c-name">
                      <span>Siddharth Banerjee</span>
                    </p>
                    <p className="c-designtion">
                        Senior Manager, Hexaware
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div class="testimonial">
                  <StaticImage
                    src="../../images/testimonials/Pawan.jpeg"
                    alt=""
                    width={104}
                    height={104}
                    placeholder="blurred"
                  />
                  <div className="testimonial-content">
                    <p className="text">
                        “We chose WowMakers based on the quality of their previous works. The entire production process was smooth, timely and up to mark.”
                    </p>
                    <p className="c-name">
                      <span>Pawan Jadhav</span>
                    </p>
                    <p className="c-designtion">
                        CEO, Lateetud
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CsTestimonials2
